.deck-vertical {
    position: absolute;
    width: 100%;
    height: 100%;
    will-change: transform;
    display: flex;
    align-items: start;
    justify-content: start;
    padding-top: 5%;
    padding-left: 10%;
    touch-action: none;
}

.deck-vertical > div {
    background-color: white;
    background-size: auto 85%;
    background-repeat: no-repeat;
    background-position: center center;
    aspect-ratio: 2 / 3;
    height: 55vh;
    will-change: transform;
    border-radius: 30px;
    box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
}

.deck-horizontal {
    position: absolute;
    width: 100%;
    height: 100%;
    will-change: transform;
    display: flex;
    align-items: end;
    justify-content: start;
    padding-bottom: 5%;
    padding-right: 5%;
    touch-action: none;
}

.deck-horizontal > div {
    background-color: white;
    background-size: auto 85%;
    background-repeat: no-repeat;
    background-position: center center;
    aspect-ratio: 1.5 / 1;
    height: 50vh;
    will-change: transform;
    border-radius: 30px;
    box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.4), 0 10px 10px -10px rgba(50, 50, 73, 0.3);
}
